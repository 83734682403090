import moment from 'moment';


export function formatDateToMMDDYYYY(date) {
  if (!date || isNaN(new Date(date))) {
    return null;
  }

  const formattedDate = new Date(date);

  const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const day = String(formattedDate.getDate()).padStart(2, '0');
  const year = formattedDate.getFullYear();

  return `${month}/${day}/${year}`;
}


export function formateDateToDDMMYYYYHHMM(inputDate) {
  if (!inputDate || isNaN(new Date(inputDate))) {
    return null;
  }

  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

  return formattedDate;
}


export function formatToCurrentDateTime(inputDate) {
  const year = inputDate.getFullYear();
  const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  const day = String(inputDate.getDate()).padStart(2, '0');
  const hours = String(inputDate.getHours()).padStart(2, '0');
  const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  const seconds = String(inputDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function formatToYYYYMMDD(input) {
  const dateObject = new Date(input);
  const formattedDate = dateObject.toLocaleDateString('en-GB', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  return formattedDate;
}

export function formatToLocalDateTime(inputTime) {
  const dateTime = new Date(inputTime + 'Z');

  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = `${dateTime.toLocaleDateString('en-IN', options)}`;

  return formattedDate;
}
export const convertUTCToLocal = (date, format) =>{
  format = format || 'MM/DD/YYYY'; // YYYY-MM-DD hh:mm:ss
  const localTime = moment.utc(date).toDate();
  return moment(localTime).format(format);
}
